import {
    forwardRef, Ref, useCallback, useEffect, useState,
} from 'react';
import { Paginator, Message } from '@twilio/conversations';
import { ListRange, VirtuosoHandle } from 'react-virtuoso';
import { RenderIf } from 'react-rainbow-components';
import useCustomer from 'data/firestore/agent/customer/use';
import { CHAT_PAGE_SIZE } from '../../../constants';
import { ConversationParticipant } from '../types';
import formatDate from './helpers/formatDate';
import useGroupMessages from './hooks/useGroupMessages';
import usePrependItems from './hooks/usePrependItems';
import DateBadge, { StyledDateBadge } from './dateBadge';
import MessageBubble from './Message';
import useShowScrollDownButton from './hooks/useShowScrollDownButton';
import {
    StyledVirtuoso,
    StyledEmptyMessage,
    ItemContainer,
    Space,
    StyledDownButton,
    StyledUnreadDot,
    StyledChevronDown,
} from './styled';

const virtuosoComponents = {
    EmptyPlaceholder: () => (
        <StyledEmptyMessage
            message={(
                <>
                    <p>No messages here yet...</p>
                    <p>Send a message by typing something in the field below.</p>
                </>
            )}
        />
    ),
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const virtuosoItemContent = (_, msg) => {
    const { showGroupDate, sid, dateCreated } = msg;
    if (showGroupDate) {
        return (
            <ItemContainer key={sid}>
                <Space />
                <DateBadge label={formatDate(dateCreated.toISOString())} />
                <Space />
                <MessageBubble message={msg} />
            </ItemContainer>
        );
    }
    return <MessageBubble key={msg.sid} message={msg} />;
};

interface SMSChatMessagesProps {
    agentId: string;
    customerId: string;
    participants?: Record<string, ConversationParticipant>;
    messages?: Paginator<Message>;
    messagesCount: number;
    onReadMessages?: () => void;
}

const SMSChatMessages = forwardRef(
    ({
        agentId,
        customerId,
        messages,
        messagesCount,
        participants = {},
        onReadMessages = () => {},
    }: SMSChatMessagesProps, ref: Ref<VirtuosoHandle> | undefined) => {
        const [atBottom, setAtBottom] = useState(false);
        const [isScrolling, setScrolling] = useState(false);
        const [firstItemDate, setFirstItemDate] = useState<Date | null>();
        const groupedMessages = useGroupMessages({ messages, participants });
        const { firstItemIndex, prependItems } = usePrependItems(customerId, messagesCount);
        const showButton = useShowScrollDownButton(atBottom);

        const { data: customer } = useCustomer(
            agentId,
            customerId,
            {
                disabled: !agentId || !customerId,
            },
        );

        const handleScrollDownClick = useCallback(
            () => {
                if (messages?.items) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ref?.current?.scrollToIndex({
                        index: messages.items.length - 1,
                        behavior: 'smooth',
                    });
                }
            },
            [messages?.items, ref],
        );

        const handleRangeChanged = useCallback(
            (range: ListRange) => setFirstItemDate(
                messages?.items?.[range.startIndex - firstItemIndex]?.dateCreated,
            ),
            [firstItemIndex, messages?.items],
        );

        useEffect(() => {
            if (customer?.hasNewMessage && atBottom) {
                onReadMessages();
            }
        }, [atBottom, customer?.hasNewMessage, onReadMessages]);

        return (
            <>
                <StyledVirtuoso
                    ref={ref}
                    firstItemIndex={firstItemIndex}
                    initialTopMostItemIndex={CHAT_PAGE_SIZE - 1}
                    data={groupedMessages}
                    startReached={prependItems}
                    atBottomStateChange={setAtBottom}
                    isScrolling={setScrolling}
                    rangeChanged={handleRangeChanged}
                    components={virtuosoComponents}
                    itemContent={virtuosoItemContent}
                    followOutput="smooth"
                />
                <StyledDateBadge
                    label={formatDate(firstItemDate?.toISOString())}
                    isScrolling={isScrolling}
                />
                <RenderIf isTrue={showButton}>
                    <StyledDownButton
                        onClick={handleScrollDownClick}
                    >
                        <RenderIf isTrue={customer?.hasNewMessage && !atBottom}>
                            <StyledUnreadDot />
                        </RenderIf>
                        <StyledChevronDown />
                    </StyledDownButton>
                </RenderIf>
            </>
        );
    },
);

export default SMSChatMessages;
