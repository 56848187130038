import { ConversationParticipant } from 'components/SMSChat/types';

const resolveUserFromAuthor = ({
    participants = {},
    author = '',
}: {
    participants?: Record<string, ConversationParticipant>;
    author?: string | null;
}) => participants[author as string] || {
    uid: 'unknown',
    displayName: 'Former Member',
};

export default resolveUserFromAuthor;
