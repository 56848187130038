import {
    ReactNode, RefObject, useMemo, useRef,
} from 'react';
import { RenderIf, useDefaultTooltipConnector, InternalTooltip } from 'react-rainbow-components';
import ButtonIcon from 'components/ButtonIcon';
import AvatarsGroup from 'components/AvatarsGroup';
import Close from 'components/icons/close';
import ArrowLeft from 'components/icons/arrowLeft';
import UserAvatar from 'components/UserAvatar';
import { EntityGet } from 'data/firestore/types';
import { Customer, ItemUser } from 'data/firestore/globals';
import formatIntlPhoneNumber from 'data/services/phone/formatter';
import getInitials from 'data/services/string/getInitials';
import ListTile from './ListTile';
import {
    CloseButton, Container, Content, Header, Footer,
    CustomerInfoContainer,
} from './styled';
import ChatInput from './chatIinput';
import { ConversationParticipant } from './types';
import getDisplayNameForParticipant from './helpers/getDisplayNameForParticipant';
import CustomerInfo from './CustomerInfo';

interface SMSChatLayoutProps {
    children: ReactNode;
    hideInput?: boolean;
    customerData: EntityGet<Customer> | ConversationParticipant;
    onRequestClose?: () => void;
    onBackClick?: () => void;
    inputRef?: RefObject<any>,
    onTextSend?: (value: string) => void;
}

const SMSChatLayout = ({
    customerData,
    onRequestClose = () => {},
    hideInput = false,
    children,
    inputRef,
    onTextSend = () => {},
    onBackClick = () => {},
}: SMSChatLayoutProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef();

    const {
        onMouseEnter,
        onMouseLeave,
        isVisible,
    } = useDefaultTooltipConnector({
        tooltipRef,
        triggerRef: () => containerRef as any,
    });

    const customerDisplayName = getDisplayNameForParticipant(customerData);

    const avatar = useMemo(() => {
        if (Array.isArray(customerData)) {
            return (
                <AvatarsGroup
                    users={customerData}
                    expandOnHover
                />
            );
        }

        const { id } = customerData as Customer;
        const { photoURL, uid } = customerData as ItemUser;

        return (
            <UserAvatar
                initials={getInitials(customerDisplayName)}
                src={photoURL}
                value={id || uid}
            />
        );
    }, [customerData, customerDisplayName]);

    const phoneNumber = (
        Array.isArray(customerData)
            ? customerData.at(0)?.phoneNumber
            : customerData.phoneNumber
    );

    const shouldShowTooltip = useMemo(
        () => isVisible && Array.isArray(customerData) && customerData.length > 1,
        [customerData, isVisible],
    );

    return (
        <Container>
            <Header>
                <ButtonIcon
                    icon={<ArrowLeft />}
                    onClick={onBackClick}
                />
                <CustomerInfoContainer
                    ref={containerRef}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <ListTile
                        icon={avatar}
                        title={customerDisplayName}
                        subtitle={formatIntlPhoneNumber((phoneNumber as string) || '')}
                    />
                    <InternalTooltip
                        triggerElementRef={() => containerRef}
                        ref={tooltipRef}
                        preferredPosition="bottom"
                        isVisible={shouldShowTooltip}
                    >
                        <CustomerInfo customerData={customerData} />
                    </InternalTooltip>
                </CustomerInfoContainer>
                <CloseButton icon={<Close />} onClick={onRequestClose} />
            </Header>
            <Content>
                {children}
            </Content>
            <RenderIf isTrue={!hideInput}>
                <Footer>
                    <ChatInput
                        ref={inputRef}
                        onTextSend={onTextSend}
                    />
                </Footer>
            </RenderIf>
        </Container>
    );
};

export default SMSChatLayout;
