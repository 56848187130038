import { useMemo } from 'react';
import { BaseProps, BoardCard, BoardColumn } from '../types';
import KanbanBoardColumn from './column';

interface KanbanBoardColumnsProps extends BaseProps {
    columns?: BoardColumn[];
    cards?: Record<string, BoardCard[]>;
}

const KanbanBoardColumns = ({
    columns = [],
    cards = {},
    cardComponent,
    columnHeaderComponent,
    columnFooterComponent,
    onColumnAddClick,
    onColumnExpand,
    onColumnCollapse,
}: KanbanBoardColumnsProps) => {
    const columnsList = useMemo(
        () => columns.map(
            (column) => (
                <KanbanBoardColumn
                    key={`kanban-board-column__${column.name}`}
                    name={column.name}
                    title={column.label}
                    column={column}
                    cards={cards[column.name]}
                    cardComponent={cardComponent}
                    columnHeaderComponent={columnHeaderComponent}
                    columnFooterComponent={columnFooterComponent}
                    onColumnAddClick={onColumnAddClick}
                    onColumnExpand={onColumnExpand}
                    onColumnCollapse={onColumnCollapse}
                />
            ),
        ),
        [
            columns,
            cards,
            cardComponent,
            columnHeaderComponent,
            columnFooterComponent,
            onColumnAddClick,
            onColumnExpand,
            onColumnCollapse,
        ],
    );

    return columnsList;
};

export default KanbanBoardColumns;
