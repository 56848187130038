import { ReactNode } from 'react';
import MetaIcon from 'components/icons/meta';
import OpportunityIcon from 'components/icons/opportunity';
import Facebook from 'components/icons/facebook';
import InstagramIcon from 'components/icons/instagram';
import Phone from 'components/icons/phone';
import { PageIcon } from './styled';

export const featuresList = [
    {
        label: 'Facebook DMs',
        description: '',
        name: 'meta.facebook.dm',
        featureSchema: 'system.meta.chat',
        group: 'Meta DMs',
        url: '',
    },
    {
        label: 'Instagram DMs',
        description: '',
        name: 'meta.instagram.dm',
        featureSchema: 'system.meta.chat',
        group: 'Meta DMs',
        url: '',
    },
    {
        label: 'Summary',
        description: 'Generate brief summaries for each opportunity, making it easier.',
        name: 'system.opportunity.summary',
        featureSchema: 'system.opportunity.summary',
        group: 'Opportunities',
    },
    {
        label: 'Missed Call SMS',
        description: 'Send a SMS for missed calls.',
        name: 'system.call.missed_call_sms',
        featureSchema: 'system.call.missed_call_sms',
        group: 'Calls',
    },
];

export const groupIconMap: Record<string, ReactNode> = {
    'Meta DMs': <MetaIcon />,
    Opportunities: <OpportunityIcon />,
    Calls: <Phone />,
};

export const groupDescription: Record<string, string> = {
    'Meta DMs': 'Connect your Facebook and Instagram accounts with an AI Persona to automate and personalize your DMs.',
    Opportunities: 'Connect AI Personas to automate and personalize actions within Opportunities, enhancing your lead qualification process.',
    Call: 'Connect AI Personas to automate and personalize actions within Calls, improving customer interactions and boosting satisfaction.',
};

export const featureIconMap: Record<string, ReactNode> = {
    'meta.facebook.dm': <Facebook />,
    'meta.instagram.dm': <InstagramIcon />,
    'system.opportunity.summary': <PageIcon />,
    'system.call.missed_call_sms': <Phone />,
};
