import { useMemo, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { InternalTooltip, useDefaultTooltipConnector } from 'react-rainbow-components';
import Plus from 'components/icons/plus';
import SidebarShrinkIcon from 'components/icons/sidebarShrink';
import SidebarExpandIcon from 'components/icons/sidebarExpand';
import {
    ColumnContainer, ColumnContent, ColumnFooter, ColumnHeader, ColumnHeaderActions,
    StyledButtonIcon, ColumnCards, StyledGradient,
    StyledVirtuoso, ExpandButtonIcon,
} from './styled';
import {
    BaseProps, BoardCard, BoardColumn,
    KanbanBoardCardComponent,
} from '../types';
import KanbandBoardCard from './card';
import ColumnTitle from './columnTitle';

const getVirtuosoItemContent = (
    cardComponent?: KanbanBoardCardComponent,
// eslint-disable-next-line react/function-component-definition
) => (index: number, cardData: BoardCard) => {
    const { column, id } = cardData;
    return (
        <KanbandBoardCard
            key={`kanban-board-card__${column}_${id}`}
            component={cardComponent}
            index={index}
            {...cardData}
        />
    );
};

interface KanbanBoardColumnProps extends BaseProps {
    name: string;
    title?: string;
    column: BoardColumn;
    cards?: BoardCard[];
}

const KanbanBoardColumn = ({
    name,
    title,
    column,
    cards = [],
    cardComponent,
    columnHeaderComponent: HeaderComponent = ColumnTitle,
    columnFooterComponent: FooterComponent,
    onColumnAddClick = () => {},
    onColumnCollapse = () => {},
    onColumnExpand = () => {},
}: KanbanBoardColumnProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef();

    const {
        onMouseEnter,
        onMouseLeave,
        isVisible,
    } = useDefaultTooltipConnector({
        tooltipRef,
        triggerRef: () => containerRef as any,
    });

    const footer = useMemo(
        () => FooterComponent && (
            <ColumnFooter>
                <FooterComponent cards={cards} />
            </ColumnFooter>
        ),
        [FooterComponent, cards],
    );

    if (column.collapsed) {
        return (
            <>
                <ColumnContainer
                    ref={containerRef}
                    onClick={() => onColumnExpand(column)}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    collapsed
                >
                    <ColumnHeader collapsed>
                        <HeaderComponent
                            collapsed
                            title={title}
                            column={column}
                            cards={cards}
                        />
                    </ColumnHeader>
                    <ExpandButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        icon={<SidebarExpandIcon />}
                        onClick={() => onColumnExpand(column)}
                    />
                </ColumnContainer>
                <InternalTooltip
                    triggerElementRef={() => containerRef}
                    ref={tooltipRef}
                    preferredPosition="top"
                    isVisible={isVisible}
                >
                    <div>Expand Column</div>
                </InternalTooltip>
            </>
        );
    }

    return (
        <ColumnContainer>
            <ColumnHeader>
                <HeaderComponent
                    title={title}
                    column={column}
                    cards={cards}
                />
                <ColumnHeaderActions>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        icon={<Plus />}
                        onClick={() => onColumnAddClick(name)}
                        tooltip="New Opportunity"
                    />
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        size="small"
                        icon={<SidebarShrinkIcon />}
                        onClick={() => onColumnCollapse(column)}
                        tooltip="Collapse Column"
                    />
                </ColumnHeaderActions>
            </ColumnHeader>
            <ColumnContent>
                <Droppable
                    droppableId={name}
                    mode="virtual"
                >
                    {(provided, snapshot) => (
                        <ColumnCards
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            <StyledVirtuoso
                                data={cards}
                                initialTopMostItemIndex={0}
                                scrollerRef={provided.innerRef as any}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                itemContent={getVirtuosoItemContent(cardComponent)}
                            />
                        </ColumnCards>
                    )}
                </Droppable>
                <StyledGradient position="bottom" />
            </ColumnContent>
            {footer}
        </ColumnContainer>
    );
};

export default KanbanBoardColumn;
