import styled from 'styled-components';
import { GREY_50 } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 16rem;
    align-items: flex-start;
    gap: 0.5rem;
`;

export const Title = styled.h2`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: left;
    margin-bottom: 0.5rem;
`;

export const UserLabel = styled.div`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_50};
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
`;
