import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC_AurDf1a5C1gq_ykSQOVzywB4pgC7lP4",
  authDomain: "prompt-dataset.firebaseapp.com",
  projectId: "prompt-dataset",
  storageBucket: "prompt-dataset.appspot.com",
  messagingSenderId: "1090729186728",
  appId: "1:1090729186728:web:acb5560b27ff9c9855b99d",
  measurementId: "G-KPYXT7SDY0",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
