import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Conversation } from '@twilio/conversations';
import { ItemUser } from 'data/firestore/globals';
import useHttpMutation from 'data/firestore/useHttpMutation';
import useTwilioConversation from '../../../hooks/useTwilioConversation';

interface InitConversationRequest {
    customerId: string;
}

interface InitConversationResponse {
    conversationSid: string;
    participants: Record<string, ItemUser>;
}

interface HookResult {
    conversation: Conversation;
    participants: Record<string, ItemUser | ItemUser[]>;
}

const useConversation = (customerId?: string) => {
    const { agentId = '' } = useParams();
    const { client } = useTwilioConversation();

    const {
        mutateAsync: addChatParticipant,
    } = useHttpMutation<InitConversationRequest, InitConversationResponse>({
        pathname: `/agents/${agentId}/conversations/init`,
        method: 'POST',
    });

    // eslint-disable-next-line max-len
    return useQuery<unknown, unknown, HookResult>(
        ['conversation', customerId],
        async () => {
            if (!customerId || !agentId) return null;
            const { conversationSid, participants } = await addChatParticipant({
                body: { customerId },
            });
            const conversation = await client?.peekConversationBySid(conversationSid);
            return {
                conversation,
                participants,
            };
        },
        { cacheTime: 0 },
    );
};

export default useConversation;
