import { AvatarSize } from 'react-rainbow-components/components/types';
import { ItemUser } from 'data/firestore/globals';
import getDisplayName from 'data/services/profile/getDisplayName';
import getInitials from 'data/services/string/getInitials';
import { Container, StyledAvatar } from './styled';

interface AvatarsGroupProps {
    users: ItemUser[];
    size?: AvatarSize;
    expandOnHover?: boolean;
}

const AvatarsGroup = ({
    users = [],
    size = 'medium',
    expandOnHover = false,
}: AvatarsGroupProps) => (
    <Container size={size} expandOnHover={expandOnHover}>
        {users.map((user) => (
            <StyledAvatar
                value={user?.uid}
                initials={getInitials(getDisplayName(user))}
                src={user?.photoURL}
            />
        ))}
    </Container>
);

export default AvatarsGroup;
