import styled, { css } from 'styled-components';
import { AvatarSize } from 'react-rainbow-components/components/types';
import UserAvatar from 'components/UserAvatar';
import { color } from 'theme';

export const Container = styled.div<{ size: AvatarSize, expandOnHover: boolean }>`
    display: inline-flex;
    
    > :not(:first-child) {
        transition: all 150ms ease-in;
        ${(props) => props.size === 'large'
            && css`
            margin-left: -1.6rem;
        `}
        ${(props) => props.size === 'medium'
            && css`
            margin-left: -1.88rem;
        `}
        ${(props) => props.size === 'small'
            && css`
            margin-left: -0.75rem;
        `}
        ${(props) => props.size === 'x-small'
            && css`
            margin-left: -0.625rem;
        `}

    }       
    ${(props) => props.expandOnHover && css`
        &:hover {
            > :not(:first-child) {
                margin-left: -0.5rem;
            }
        }
    `};
`;

export const StyledAvatar = styled(UserAvatar)`
    border: 2px solid ${color('background.main')};
    pointer-events: none;
`;
