import styled, { css } from 'styled-components';
import { Virtuoso } from 'react-virtuoso';
import ButtonIcon from 'components/ButtonIcon';
import Card from 'components/Card';
import Gradient from 'components/Gradient';
import {
    PURPLE_500, DIVIDER, BACKGROUND,
    GREY_200,
} from '../../../constants';

export const ColumnContainer = styled.div<{ collapsed?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    align-items: flex-start;
    border-radius: 1rem;
    background: ${BACKGROUND};
    width: 340px;
    flex-shrink: 0;
    height: 100%;
    position: relative;

    ${(props) => props.collapsed && css`
        width: 3.5rem;

        &:hover {
            background: ${GREY_200};
            cursor: pointer;
        }
    `};
`;

export const ColumnHeader = styled.div<{ collapsed?: boolean }>`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    padding: 0 0.75rem;

    ${(props) => props.collapsed && css`
        transform: rotate(90deg);
        transform-origin: 50% 50%;
        white-space: nowrap;
        margin-top: 0.75rem;
    `};
`;

export const ColumnHeaderActions = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const ColumnContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    overflow: hidden;
    align-self: stretch;
    box-sizing: border-box;
    padding-top: 0.5rem;
    padding-bottom: 0;
`;

export const ColumnCards = styled.div<{ isDraggingOver?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 0.5rem;
    overflow: hidden;
    padding: 0.25rem 0 1.5rem;
    box-sizing: border-box;

    ${(props) => props.isDraggingOver && css`
        background: ${BACKGROUND};
    `};
`;

export const ColumnFooter = styled.div`
    display: flex;
    padding: 0.75rem 0.75rem 0;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid ${DIVIDER};
`;

export const ColumnTitleContainer = styled.div`
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.875rem;
    transform: rotate(0deg);
`;

export const ColumnTitleLabel = styled.span<{ removable: boolean }>`
    display: inline-flex;
    padding: 0.25rem 0.625rem;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    overflow: hidden;
    margin-right: 0.44rem;
    text-transform: ellipsis;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.875rem;

    ${(props) => !props.removable && css`
        text-transform: capitalize;
    `};
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    width: 1.5rem;
    height: 1.5rem;

    svg {
        width: 1rem !important;
        height: 1rem !important;
        font-size: 1rem !important;
    }
`;

export const ExpandButtonIcon = styled(ButtonIcon)`
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    svg {
        width: 1rem !important;
        height: 1rem !important;
        font-size: 1rem !important;
    }
`;

export const StyledCard = styled(Card)`
    display: flex;
    align-self: stretch;
    flex-shrink: 0;
    border-radius: 0.375rem;
    text.overlay: ellipsis;
    padding: 0.5rem 0.25rem;
`;

export const CardContainer = styled.div<{ isDragging?: boolean }>`
    display: flex;
    align-self: stretch;
    flex-direction: column;
    border: 2px solid transparent;
    border-radius: 1rem;
    box-sizing: border-box;
    margin: 0 0.75rem;

    & > * {
        user-select: none;
    }
    
    :hover {
        cursor: move;
        transition: all 0.2s ease;
        border: 2px solid ${PURPLE_500};
    }

    :focus {
        cursor: move;
        transition: all 0.2s ease;
        outline: none;
    }

    &:focus-within {
        border: 2px solid ${PURPLE_500};
    }

    ${(props) => props.isDragging && css`
        transition: all 0.2s ease;
    `} 
`;

export const StyledGradient = styled(Gradient)`
    background-image: linear-gradient(
        to bottom,
        #F8F8F800,
        #F8F8F8 71%
    );
`;

export const StyledVirtuoso = styled(Virtuoso)`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
`;
