import getDisplayName from 'data/services/profile/getDisplayName';
import { EntityGet } from 'data/firestore/types';
import { Customer } from 'data/firestore/globals';
import { ConversationParticipant } from '../types';

const getDisplayNameForParticipant = (
    participant: EntityGet<Customer> | ConversationParticipant,
) => {
    if (Array.isArray(participant)) {
        return (
            participant.length > 1
                ? participant.map((user) => getDisplayName(user).split(' ').at(0)).join(', ')
                : getDisplayName(participant.at(0))
        );
    }

    return getDisplayName(participant);
};

export default getDisplayNameForParticipant;
