import { useCallback, useMemo } from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import useStoredValue from 'data/hooks/useStoredValue';
import { OPPORTUNITY_BOARD_STORAGE_KEY } from '../../../../../constants';

interface PipelineStorage {
    collapsedColumns: string[];
}

type BoardStorage = Record<string, PipelineStorage>;

const defaults = {
    collapsedColumns: ['prospect', 'engaged'],
};

const useBoardStorage = ({
    pipelineId = '',
}: {
    pipelineId?: string,
}) => {
    const { value = {}, save } = useStoredValue<BoardStorage>(
        OPPORTUNITY_BOARD_STORAGE_KEY,
    );

    const expandColumn = useCallback((columnName: string) => {
        if (isEmpty(pipelineId)) return;
        const { collapsedColumns, ...rest } = value[pipelineId] || defaults;
        save({
            ...value,
            [pipelineId]: {
                ...rest,
                collapsedColumns: collapsedColumns.filter((column) => column !== columnName),
            },
        });
    }, [pipelineId, save, value]);

    const collapseColumn = useCallback((columnName: string) => {
        if (isEmpty(pipelineId)) return;
        const { collapsedColumns, ...rest } = value[pipelineId] || defaults;
        save({
            ...value,
            [pipelineId]: {
                ...rest,
                collapsedColumns: [...collapsedColumns, columnName],
            },
        });
    }, [pipelineId, save, value]);

    return useMemo(
        () => ({
            ...(value[pipelineId] || defaults),
            collapseColumn,
            expandColumn,
        }),
        [collapseColumn, expandColumn, pipelineId, value],
    );
};

export default useBoardStorage;
